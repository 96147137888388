import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RestService } from '@services/rest/rest.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private restService: RestService
  ) { }

  /*------------------------------------------------------------*\
   * CHAT
  /*------------------------------------------------------------*/

  async changeStatusMate(data): Promise<HttpResponse<object>> {
    const url = 'student/change-status-with-mates';
    return await this.restService.postData(data, url);
  }

  async getChatContacts(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/chat-contacts';
    return await this.restService.postData(data, url);
  }

  async getChatByID(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/chat-messages-with-friend';
    return await this.restService.postData(data, url);
  }

  async getChatProfile(data = {}): Promise<HttpResponse<object>> {
    const url = 'student/chat-profile';
    return await this.restService.postData(data, url);
  }

  async getChatProfileByID(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/friend-chat-profile';
    return await this.restService.postData(data, url);
  }

  async getMessagesNotChecked(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/chat-messages-not-checked';
    return await this.restService.postData(data, url);
  }

  async checkMessages(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/check-messages';
    return await this.restService.postData(data, url);
  }

  async sendChatMessage(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/send-chat-message';
    return await this.restService.postData(data, url);
  }

  async createChatProfile(data: {[key: string]: any}): Promise<HttpResponse<object>> {
    const url = 'student/set-chat-profile';
    return await this.restService.postData(data, url);
  }

  async updateChatProfile(data: {[key: string]: any}): Promise<HttpResponse<object>> {
    const url = 'student/update-chat-profile';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * CHAT
  /*------------------------------------------------------------*/

  async checkDiscountCode(data): Promise<HttpResponse<object>> {
    const url = 'english/check-discount-code';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * COINS
  /*------------------------------------------------------------*/

  async getCoins(): Promise<HttpResponse<object>> {
    const url = 'student/info-status-coins';
    return await this.restService.postData({}, url);
  }

  async getRewards(): Promise<HttpResponse<object>> {
    const url = 'english/rewards';
    return await this.restService.postData({}, url);
  }

  async updateCoins(): Promise<HttpResponse<object>> {
    const url = 'student/update-status-coins';
    return await this.restService.postData({}, url);
  }

  /*------------------------------------------------------------*\
   * LEVELS
  /*------------------------------------------------------------*/

  async getCategories(data: any): Promise<HttpResponse<object>> {
    const url = 'english/categories';
    return await this.restService.postData(data, url);
  }

  async getCategoriesCompleted(data: any): Promise<HttpResponse<object>> {
    const url = 'student/get-categories-completed';
    return await this.restService.postData(data, url);
  }

  async getCategoriesLevels(data: any): Promise<HttpResponse<object>> {
    const url = 'english/category-levels';
    return await this.restService.postData(data, url);
  }

  async getLevels(data: any): Promise<HttpResponse<object>> {
    const url = 'english/levels';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * PAYMENTS
  /*------------------------------------------------------------*/

  async allowWebPurchase(data = {}): Promise<HttpResponse<object>> {
    const url = 'english/allow-web-purchase';
    return await this.restService.postData(data, url);
  }

  async getBizumToken(data: any): Promise<HttpResponse<object>> {
    const url = 'english/get-bizum-token';
    return await this.restService.postData(data, url);
  }

  async getRedsysToken(data: any): Promise<HttpResponse<object>> {
    const url = 'english/get-redsys-token';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * PROFILE
  /*------------------------------------------------------------*/

  async getProfile(): Promise<HttpResponse<object>> {
    const url = 'student/profile';
    return await this.restService.postData({}, url);
  }

  async updateProfile(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/update-profile';
    return await this.restService.postData(data, url);
  }

  async searchProfile(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/search-conversation-mates';
    return await this.restService.postData(data, url);
  }

  async updatePhoto(data: any): Promise<HttpResponse<object>> {
    const url = 'student/update-photo';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * PURCHASES
  /*------------------------------------------------------------*/

  async checkPurchases(): Promise<HttpResponse<object>> {
    const url = 'english/check-purchases';
    return await this.restService.postData({}, url);
  }

  async checkExpiredPurchases(): Promise<HttpResponse<object>> {
    const url = 'english/expired-purchases';
    return await this.restService.getData(url);
  }

  async checkPurchasesExpiringSoon(): Promise<HttpResponse<object>> {
    const url = 'student/check-purchases-expiring-soon';
    return await this.restService.postData({}, url);
  }

  async getPurchase(data: any): Promise<HttpResponse<object>> {
    const url = 'english/purchase';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * PUSH
  /*------------------------------------------------------------*/

  async pushRegister(data: any): Promise<HttpResponse<object>> {
    const url = 'push/register';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * QUESTIONS
  /*------------------------------------------------------------*/

  async getQuestionListening(data: any): Promise<HttpResponse<object>> {
    const url = 'english/listening';
    return await this.restService.postData(data, url);
  }

  async getQuestionListening2(data: any): Promise<HttpResponse<object>> {
    const url = 'english/listening2';
    return await this.restService.postData(data, url);
  }

  async getQuestionListenUp(data: any): Promise<HttpResponse<object>> {
    const url = 'english/listenUp';
    return await this.restService.postData(data, url);
  }

  async getQuestionSpeaking(data: any): Promise<HttpResponse<object>> {
    const url = 'english/speaking';
    return await this.restService.postData(data, url);
  }

  async getQuestionSpeakUp(data: any): Promise<HttpResponse<object>> {
    const url = 'english/speakUp';
    return await this.restService.postData(data, url);
  }

  async getQuestionTranslating(data: any): Promise<HttpResponse<object>> {
    const url = 'english/translating';
    return await this.restService.postData(data, url);
  }

  async getQuestionTranslating2(data: any): Promise<HttpResponse<object>> {
    const url = 'english/translating2';
    return await this.restService.postData(data, url);
  }

  async getQuestionRandomTest(data = {}): Promise<HttpResponse<object>> {
    const url = 'english/generate-random-test';
    return await this.restService.postData(data, url);
  }


  /*------------------------------------------------------------*\
   * STATISTICS
  /*------------------------------------------------------------*/

  async getStatistics(): Promise<HttpResponse<object>> {
    const url = 'student/student-statistics';
    return await this.restService.postData({}, url);
  }

  /*------------------------------------------------------------*\
   * UNITS
  /*------------------------------------------------------------*/

  async getUnit(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'english/unit';
    return await this.restService.postData(data, url);
  }

  async getUnits(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'english/units';
    return await this.restService.postData(data, url);
  }

  async unlock(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'english/unlock';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * USER
  /*------------------------------------------------------------*/

  async changePassword(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'student/change-password';
    return await this.restService.postData(data, url);
  }

  async deleteUser(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'english/delete-account';
    return await this.restService.postData(data, url);
  }

  /*------------------------------------------------------------*\
   * VOCABULARY
  /*------------------------------------------------------------*/

  async vocabulary(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'english/vocabulary';
    return await this.restService.postData(data, url);
  }

  async rememberVocabulary(data: { [key: string]: any }): Promise<HttpResponse<object>> {
    const url = 'english/vocabulary';
    return await this.restService.postData(data, url);
  }

}
