import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { ApiService } from '@services/api/api.service';
import { UtilsService } from '@services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckAllowPurchaseGuard implements CanActivate {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private utilsService: UtilsService
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise(
      async resolve => {
        const resp: any = await this.apiService.allowWebPurchase();
        if (resp) {
            const currentStatus = resp.body.status;
            this.utilsService.currentAllowWebPurchase = currentStatus;
            if (currentStatus) {
                resolve(true);
            } else {
                this.router.navigate(['/home']);
                resolve(false);
            }
        }
      }
    );
  }

}
