import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax-image',
  templateUrl: './parallax-image.component.html',
  styleUrls: ['./parallax-image.component.scss'],
})
export class ParallaxImageComponent implements OnInit {

  @Input() customClass = '';
  @Input() image: string;
  @Input() imageBg: string;

  constructor() { }

  ngOnInit() { }

}
