import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: WebSocket | null = null;
  private eventListeners: { [key: string]: ((data: any) => void)[] } = {};
  private reconnectInterval = 8000; // Tiempo en milisegundos para intentar reconectar
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private lastLoginMessage: any = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')!) : null;
  private pendingMessages: any[] = [];
  private isConnecting: boolean = false;
  private logout: boolean = false;
  private isLoggedIn: boolean = false;

  private openSubject: Subject<Event> = new Subject<Event>();
  private closeSubject: Subject<Event> = new Subject<Event>();
  private errorSubject: Subject<Event> = new Subject<Event>();

  connect() {
    if (this.isConnecting || (this.socket && this.socket.readyState === WebSocket.OPEN)) {
      //console.log('Ya se está intentando conectar o la conexión está abierta.', JSON.stringify(this.lastLoginMessage));

      if (this.socket && this.socket.readyState === WebSocket.OPEN && !this.isLoggedIn) {
        //console.log('Conexión abierta, pero no se ha hecho login. Intentando login...');
        this.sendLoginMessage();
      }
      return;
    }

    this.isConnecting = true;
    this.socket = new WebSocket('https://websocket.wellington-academy.com');

    this.socket.onopen = (event) => {
      //console.log('Conectado al servidor WebSocket');
      this.isConnecting = false;
      this.reconnectAttempts = 0;
      this.openSubject.next(event);

      // Enviar mensaje de login
      this.sendLoginMessage();
    };

    this.socket.onmessage = (event) => {
      //console.log('Mensaje del servidor:', event.data);
      this.handleMessage(event.data);
    };

    this.socket.onclose = (event) => {
      //console.log('Desconectado del servidor WebSocket:', event, this.reconnectAttempts);
      this.isConnecting = false;
      this.isLoggedIn = false;
      this.closeSubject.next(event);
      if (!this.logout) {
        this.handleClose();
      }
    };

    this.socket.onerror = (event) => {
      //console.error('Error en la conexión WebSocket:', event);
      this.isConnecting = false;
      this.errorSubject.next(event);
      this.handleClose();
    };
  }

  sendMessage(message: any) {
    if (!message) return;
    if (message.type === 'logout') {
      //console.log('logout');
      this.logout = true;
      this.isLoggedIn = false;
      this.lastLoginMessage = null;
      localStorage.removeItem('session');
      this.pendingMessages = []; 
      this.close();
      setTimeout(() => {
        this.connect(); 
      }, 1000); 
      return; 
    }

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      if (this.isLoggedIn) {
        this.socket.send(JSON.stringify(message));
        //console.log('Mensaje enviado:', message);
      } else {
        if(message.type==='login'){
          this.socket.send(JSON.stringify(message));
        }else{
          //console.log('El usuario no está logueado. Guardando mensaje en cola.', message);
          this.pendingMessages.push(message);
        }
      }
    } else {
      //console.log('La conexión WebSocket no está abierta. Mensaje en espera:', message);
      if (!this.logout) {
        this.pendingMessages.push(message);
        if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
          this.connect();
        }
      }
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }

  onMessage(event: string, callback: (data: any) => void) {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(callback);
  }

  private handleMessage(message: string) {
    const parsedMessage = JSON.parse(message);
    const event = parsedMessage.type;

    if (parsedMessage.sessionId) {
      this.isLoggedIn = true;
      this.logout = false;
      const sessionId = parsedMessage.sessionId;
      this.lastLoginMessage = { type: 'login', sessionId };
      localStorage.setItem('session', JSON.stringify(this.lastLoginMessage));

      this.sendPendingMessages();
    }

    if (this.eventListeners[event]) {
      this.eventListeners[event].forEach(callback => callback(parsedMessage));
    } else {
      //console.warn(`No hay listeners para el evento: ${event}`);
    }
  }

  private async sendLoginMessage() {
    if (this.lastLoginMessage) {
      this.sendMessage(this.lastLoginMessage);
    } else {
      const userId = localStorage.getItem('student_id');
      if (userId) {
        const userIP = await this.getUserIP();
        this.sendMessage({ type: 'login', studentId: userId, ip: userIP});
      } else {
        //console.error('No se encontró el student_id en el localStorage');
      }
    }
  }

  private sendPendingMessages() {
    if (this.isLoggedIn) {
      //console.log('Enviando mensajes pendientes...');
      while (this.pendingMessages.length > 0) {
        const message = this.pendingMessages.shift();
        this.sendMessage(message);
      }
    } else {
      //console.log('Esperando a que el usuario esté logueado para enviar mensajes pendientes.');
    }
  }

  private handleClose() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      setTimeout(() => {
        this.reconnectAttempts++;
        if (!this.logout) {
          //console.log('Intentando reconectar...');
          this.connect();
        }
      }, this.reconnectInterval);
    } else {
      //console.error('Máximo número de intentos de reconexión alcanzado.');
    }
  }

  reconnect() {
    this.reconnectAttempts = 0;
    if (!this.logout) {
      //console.log('Intentando reconectar...');
      this.connect();
    }
  }

  async getUserIP(): Promise<string> {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error obteniendo la IP del usuario:', error);
      return 'unknown';
    }
  }

  // Métodos para suscribirse a los eventos WebSocket
  onOpen(): Observable<Event> {
    return this.openSubject.asObservable();
  }

  onClose(): Observable<Event> {
    return this.closeSubject.asObservable();
  }

  onError(): Observable<Event> {
    return this.errorSubject.asObservable();
  }
}
