/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVarsService {

  // TODO: Remove api and progress

  api_url = 'https://www.backoffice4steps.com/api/';
  api_version = 'v1/';

  student_token = '';

  subscribed = false;

  payment: string;

  fcm_token = '';

  progress = {};

  active_modals = {
    intro: false,
    free_trial: false,
    free_trial_reminder: false,
    free_trial_reminder_date: 0,
    approach: false,
    levels_help: false,
    translating_intro: false,
    translating2_intro: false,
    listening_intro: false,
    listening2_intro: false,
    speaking_intro: false,
    speak_up_intro: false,
    listen_up_intro: false,
  };

  units_downloaded = [];

  products = {
    android: {
      // all: 'itodos_ei4s_periodo_7.',
      all: 'itodos_ei4s.',
      monthly: 'itodos_ei4s_mensual.',
      general_extra_half_level: 'ige15_ei4s.',
      general_extra_level: 'igenivel_ei4s.',
      professional_half_level: 'ip15_ei4s.',
      professional_level: 'ipnivel_ei4s.',
    },
    ios: {
      all: 'ITODOS',
      monthly: 'ITODOSMENSUAL',
      general_extra_half_level: 'IGE15C',
      general_extra_level: 'IGENIVELC',
      professional_half_level: 'IP15C',
      professional_level: 'IPNIVELC',
    },
  };

  productsPrices = {
    all: '99.99',
    monthly: '19.99',
    general_extra_half_level: '24.99',
    general_extra_level: '39.99',
    professional_half_level: '29.99',
    professional_level: '49.99',
  };

  externalLinksBase = 'https://www.english4steps.com/';
  externalLinks = {
    general_conditions: this.externalLinksBase + 'condiciones-generales',
    privacy_policy: this.externalLinksBase + 'politicadeprivacidad',
    //teachers: this.externalLinksBase + 'interactive-classes',
    teachers: 'https://www.instagram.com/GoFourSteps/',
    certificates: this.externalLinksBase + 'certificates',
    help: this.externalLinksBase + 'help',
    subscriptions: this.externalLinksBase + 'subscriptions',
    website: this.externalLinksBase + 'website'
  };

  constructor() { }
}
