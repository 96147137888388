import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-enroll-free',
  templateUrl: './modal-enroll-free.component.html',
  styleUrls: ['./modal-enroll-free.component.scss'],
})
export class ModalEnrollFreeComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {}

  closeModal(): void {
    this.modalCtrl.dismiss();
    this.router.navigate(['/tpv/unlock']);
  }

}
