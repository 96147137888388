import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {

  constructor(
    private menuCtrl: MenuController
  ) { }

  ngOnInit() {}

  async openMenu(): Promise<void> {
    const menuId = 'menu';
    const isEnabled: boolean = await this.menuCtrl.isEnabled(menuId);
    if (!isEnabled) {
      await this.menuCtrl.enable(true, menuId);
    }
    await this.menuCtrl.open(menuId);
  }

}
