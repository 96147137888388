import { Injectable } from '@angular/core';
import { WebSocketService } from '@services/shared/websocket.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatCommunicationService {
  private messages = new BehaviorSubject<any[]>([]);
  private pendingMessagesCount = new BehaviorSubject<number>(0);
  private page = 1;
  private pageSize = 13;
  public newMessages$$ = new BehaviorSubject<any[]>([]);
  public newMessages$ = this.newMessages$$.asObservable();

  constructor(
    private webSocketService: WebSocketService
  ) {
    this.initializeSocketListeners();
  }

  private initializeSocketListeners() {
    this.webSocketService.onMessage('messagesFetched', (data: any) => {
      if (data.status === 'success') {
        this.addMessages(data.messages);
        this.checkForPendingMessages();
      }
    });

    this.webSocketService.onMessage('newMessage', (data: any) => {
      //console.log('MENSAJE RECIBIDO', data)
      if (data) {
        this.newMessages$$.next([...this.newMessages$$.value,data])
      }
    });

    this.webSocketService.onMessage('messagesChecked', (data: any) => {
      this.pendingMessagesCount.next(data.unreadCount);
    });

    this.webSocketService.onClose().subscribe(event => {
      //console.log('Chat WebSocket disconnected, attempting reconnection...', event);
      this.handleReconnection();
    });

    this.webSocketService.onError().subscribe(error => {
      //console.error('Chat WebSocket error:', error);
      this.handleReconnection();
    });
  }

  private handleReconnection() {
    setTimeout(() => {
      this.webSocketService.reconnect();
    }, 5000);
  }

  sendMessage(message: any) {
    this.webSocketService.sendMessage(message);
    // this.fetchMessagesPage(this.page);
  }

  getMessages(): Observable<any[]> {
    return this.messages.asObservable();
  }

  getPendingMessagesCount(): Observable<number> {
    return this.pendingMessagesCount.asObservable();
  }

  fetchMessagesPage(page: number) {
    const recipientId = +localStorage.getItem('student_id');
    const fetchParams = { type: 'fetchMessages', recipientId, page, pageSize: this.pageSize };
    this.webSocketService.sendMessage(fetchParams);
  }

  nextPage() {
    this.page += 1;
    this.fetchMessagesPage(this.page);
  }

  resetPagination() {
    this.page = 1;
    this.messages.next([]);
  }

  markMessagesAsRead() {
    const recipientId = +localStorage.getItem('student_id');
    const markAsReadParams = {
      type: 'markMessagesAsRead',
      senderType: 'professor',
      recipientId
    };

    this.webSocketService.sendMessage(markAsReadParams);
    this.addMessages(this.newMessages$$.value)
  }

  private addMessages(newMessages: any[]) {
    //console.log('newMessages',newMessages)
    const currentMessages = this.messages.value;
    this.messages.next([...currentMessages, ...newMessages]);
  }

  checkForPendingMessages() {
    const recipientId = +localStorage.getItem('student_id');
    this.webSocketService.sendMessage({ type: 'checkUnreadMessages', senderType: 'professor', recipientId });
  }
}
