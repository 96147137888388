import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { PurchasesService } from '@services/purchases/purchases.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-modal-all-payment-monthly',
  templateUrl: './modal-all-payment-monthly.component.html',
  styleUrls: ['./modal-all-payment-monthly.component.scss'],
})
export class ModalAllPaymentMonthlyComponent implements OnInit {

  productId: string;

  constructor(
    public globalVarsService: GlobalVarsService,
    public utilsService: UtilsService,
    private platform: Platform,
    private modalCtrl: ModalController,
    private purchasesService: PurchasesService,
  ) { }

  ngOnInit(): void {
    if (this.platform.is('android')) {
      this.productId = this.globalVarsService.products.android.monthly;
    } else {
      this.productId = this.globalVarsService.products.ios.monthly;
    }
  }

  closeModal(): void {
    this.utilsService.play('back');
    this.modalCtrl.dismiss();
  }

  purchaseAllPayment(): void {
    this.purchasesService.buyAllMonthly(this.modalCtrl);
  }

}
