/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { CodePush } from '@awesome-cordova-plugins/code-push/ngx';
import { Push } from '@awesome-cordova-plugins/push/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';

import { HttpRequestInterceptor } from '@interceptors/http-request.interceptor';
import { HttpResponseInterceptor } from '@interceptors/http-response.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from '@components/components.module';

import { TimeAgoClock, TimeAgoFormatter } from "./helpers/time_ago_clock";
import { TimeagoClock, TimeagoFormatter, TimeagoIntl, TimeagoModule} from 'ngx-timeago';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ComponentsModule,
    TimeagoModule.forRoot({
      formatter: {provide: TimeagoFormatter, useClass: TimeAgoFormatter},
      clock: {provide: TimeagoClock, useClass: TimeAgoClock},
    }),
  ],
  providers: [
    Media,
    NativeAudio,
    CodePush,
    Push,
    File,
    FileTransfer,
    ScreenOrientation,
    SocialSharing,
    SpeechRecognition,
    StatusBar,
    SplashScreen,
    Storage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    TimeagoIntl,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
