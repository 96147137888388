import { Sound } from '@interfaces/sound';

export const RANDOM_SOUNDS_DATA: Sound[] = [
    { key: 'man-1', asset: 'assets/sounds/random/man-1.mp3' },
    { key: 'man-2', asset: 'assets/sounds/random/man-2.mp3' },
    { key: 'man-3', asset: 'assets/sounds/random/man-3.mp3' },
    { key: 'man-4', asset: 'assets/sounds/random/man-4.mp3' },
    { key: 'man-5', asset: 'assets/sounds/random/man-5.mp3' },
    { key: 'woman-1', asset: 'assets/sounds/random/man-1.mp3' },
    { key: 'woman-2', asset: 'assets/sounds/random/man-1.mp3' },
    { key: 'woman-3', asset: 'assets/sounds/random/man-1.mp3' },
    { key: 'woman-4', asset: 'assets/sounds/random/man-1.mp3' },
    { key: 'woman-5', asset: 'assets/sounds/random/man-1.mp3' }
];

export const RANDOM_SUCCESS_SOUNDS_DATA: Sound[] = [
    { key: 'correct', asset: 'assets/sounds/correct.mp3' },
    { key: 'ok-1', asset: 'assets/sounds/ok/ok-1.mp3' },
    { key: 'ok-2', asset: 'assets/sounds/ok/ok-2.mp3' },
    { key: 'ok-3', asset: 'assets/sounds/ok/ok-3.mp3' },
    { key: 'ok-4', asset: 'assets/sounds/ok/ok-4.mp3' },
    { key: 'ok-5', asset: 'assets/sounds/ok/ok-5.mp3' },
    { key: 'ok-6', asset: 'assets/sounds/ok/ok-6.mp3' },
    { key: 'ok-7', asset: 'assets/sounds/ok/ok-6.mp3' }
];
