/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { NativeAudio } from '@ionic-native/native-audio';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController, ModalController, Platform, ToastController, ToastOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Camera, CameraOptions, Photo } from '@capacitor/camera';
import * as confetti from 'canvas-confetti';

import { Sound } from '@interfaces/sound';
import { Toast } from '@interfaces/toast';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { StorageService } from '@services/storage/storage.service';
import { RANDOM_SOUNDS_DATA, RANDOM_SUCCESS_SOUNDS_DATA } from '@static-data/random_sounds';
import { ModalParams } from '@interfaces/modal';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  currentAllowWebPurchase = true;
  isCorrectDiscountCode = false;
  loader: any = null;
  sounds: Sound[] = [];
  audioPlayer: HTMLAudioElement = new Audio();
  forceWebAudio = false;
  defaultRandomSounds: Sound[];
  defaultSounds: Sound[];
  defaultSuccessSounds: Sound[];
  isMobile = false;

  private randomSounds: Sound[] = [];
  private renderer2: Renderer2;
  private toast: Toast | any;
  private defaultToastParams: Toast | any = {
    header: '¡Éxito!',
    message: this.translateService.instant('toast.success'),
    cssClass: 'success',
    duration: 3000
  };
  private defaultModalParams: ModalParams = {
    component: null,
    cssClass: 'c-modal',
    componentsProps: { },
    swipeToClose: true
  };

  constructor(
    private rendererFactory: RendererFactory2,
    private http: HttpClient,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private router: Router,
    private storage: Storage,
    private globalVarsService: GlobalVarsService,
    private storageService: StorageService,
    private translateService: TranslateService
  )
  {
    this.defaultSounds = [
      { key: 'back', asset: 'assets/sounds/back.mp3' },
      { key: 'correct', asset: 'assets/sounds/correct.mp3' },
      { key: 'incorrect', asset: 'assets/sounds/incorrect.mp3' },
      { key: 'incorrect-without-voice', asset: 'assets/sounds/incorrect-without-voice.mp3' },
      { key: 'next', asset: 'assets/sounds/next.mp3' },
      { key: 'ok', asset: 'assets/sounds/ok.mp3' },
      { key: 'purchase-ok', asset: 'assets/sounds/purchase-ok.mp3' },
      { key: 'tab', asset: 'assets/sounds/tab.mp3' },
      { key: 'congrats', asset: 'assets/sounds/congrats.mp3' },
      { key: 'coin-win', asset: 'assets/sounds/coin-win.mp3' },
      { key: 'passport-win', asset: 'assets/sounds/passport-win.mp3' },
      { key: 'splash', asset: 'assets/sounds/splash.mp3' },
      { key: 'bg-sound-exercises', asset: 'assets/sounds/bg-sound-exercises.mp3' },
    ];

    this.defaultRandomSounds = RANDOM_SOUNDS_DATA;
    this.defaultSuccessSounds = RANDOM_SUCCESS_SOUNDS_DATA;

    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  codeSync() {
    // this.codePush.sync();
  }

  /*========================================================================*\
   * Audio
  /*========================================================================*/

  loadDefaultSounds(): void {
    this.defaultSounds.forEach((sound) => {
      this.preload(sound.key, sound.asset);
    });
  }

  preload(key: string, asset: string): void {
    if (this.platform.is('cordova') && !this.forceWebAudio) {
      NativeAudio.preloadComplex(key, asset, 1, 1, 0);

      this.sounds.push({
        key,
        asset,
        isNative: true,
      });
    } else {
      const audio = new Audio();
      audio.src = asset;
      audio.volume = 1;

      this.sounds.push({
        key,
        asset,
        isNative: false,
      });
    }
  }

  stop(key: string,): void {
    const soundToStop = this.sounds.find((sound) => sound.key === key);

    console.log('sound to pause', soundToStop);

    if (soundToStop.isNative) {
      NativeAudio.stop(soundToStop.key).then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.audioPlayer.src = soundToStop.asset;
      if (this.audioPlayer.src) {
        this.audioPlayer.pause();
      }
    }
  }

  stopAll(): void {
    this.sounds.map(sound => {
      if (sound.isNative) {
        NativeAudio.stop(sound.key);
      } else {
        this.audioPlayer.src = sound.asset;
        if (this.audioPlayer.src) {
          this.audioPlayer.pause();
        }
      }
    });
  }

  play(key: string, volume = 1): void {
    const soundVolume: number = (volume > 1) ? 1 : volume; // fix
    const soundToPlay = this.sounds.find((sound) => sound.key === key);

    console.log('sound to play', soundToPlay);

    if (soundToPlay.isNative) {
      NativeAudio.setVolumeForComplexAsset(soundToPlay.key, soundVolume);
      NativeAudio.play(soundToPlay.key).then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.audioPlayer.src = soundToPlay.asset;
      if (this.audioPlayer.src) {
        this.audioPlayer.volume = soundVolume;
        this.audioPlayer.play();
      }
    }
  }

  playRandom(playList: any[], volume = 1): void {
    const random = Math.floor(Math.random() * playList.length);
    const key = playList[random].key;
    const soundVolume: number = (volume > 1) ? 1 : volume; // fix
    // const soundsList = (this.randomSounds.length > 0) ? this.defaultSuccessSounds : this.randomSounds;
    const soundToPlay = playList.find((sound) => sound.key === key);

    console.log('sound to play', soundToPlay);

    if (soundToPlay.isNative) {
      NativeAudio.setVolumeForComplexAsset(soundToPlay.key, soundVolume);
      NativeAudio.play(soundToPlay.key).then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.audioPlayer.src = soundToPlay.asset;
      if (this.audioPlayer.src) {
        this.audioPlayer.volume = soundVolume;
        this.audioPlayer.play();
      }
    }
  }

  loadRandomSounds(): void {
    this.defaultRandomSounds.forEach((sound) => {
      this.preloadRandom(sound.key, sound.asset);
    });
  }

  preloadRandom(key: string, asset: string): void {
    if (this.platform.is('cordova') && !this.forceWebAudio) {
      NativeAudio.preloadComplex(key, asset, 1, 1, 0);

      this.randomSounds.push({
        key,
        asset,
        isNative: true,
      });
    } else {
      const audio = new Audio();
      audio.src = asset;
      audio.volume = 1;

      this.randomSounds.push({
        key,
        asset,
        isNative: false,
      });
    }
  }

  /*========================================================================*\
   * Root Page
  /*========================================================================*/

  setRootPage(page: string): void {
    /*if (page === 'ApproachPage' && this.globalVarsService.active_modals.approach) {
      page = 'SectionsPage';
    }

    const nav = this.app.getActiveNav();
    nav.setRoot(page, {}, { animate: true, duration: 300 });*/
  }

  /*========================================================================*\
   * Loader
  /*========================================================================*/

  async showLoader(duration = 10000, message = this.translateService.instant('loader.loading')): Promise<void> {
    this.loader = await this.loadingCtrl.create({
      message,
      cssClass: 'flip-horizontal-bottom',
    });
    return await this.loader.present();
  }

  async hideLoader(): Promise<void> {
    if (this.loader) {
      return await this.loader.dismiss();
    }
  }

  /*========================================================================*\
   * IAB
  /*========================================================================*/

  linkTo(url) {
    InAppBrowser.create(url, '_blank', 'location=true, usewkwebview=yes');
  }

  linkToSystem(url) {
    InAppBrowser.create(url, '_system');
  }

  /*========================================================================*\
   * Alerts
  /*========================================================================*/

  async presentAlert(title: string, message: string, buttonText?: string, customClass?: string) {
    const alert = await this.alertCtrl.create({
      cssClass: customClass ? customClass : '',
      header: title,
      subHeader: message,
      buttons: [
        {
          text: buttonText ? buttonText : 'OK',
        },
      ],
    });

    return alert.present();
  }

  presentErrorAlert(message: string) {
    return this.presentAlert('An error has occurred.', message);
  }

  async presentAlertWithCallback(title: string, message: string): Promise<void> {
    const confirm = await this.alertCtrl.create({
      header: title,
      subHeader: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            confirm.dismiss().then(() => false);
            return false;
          },
        },
        {
          text: 'Yes',
          handler: () => {
            confirm.dismiss().then(() => true);
            return false;
          },
        },
      ],
    });

    return confirm.present();
  }

  /*========================================================================*\
   * Toast
  /*========================================================================*/

  async showToast(tp: Toast | any = this.defaultToastParams, waitDismiss = false) {
    return new Promise(async resolve => {
      const buttons = tp.buttons ? tp.buttons : [];
      const header = tp.header ? tp.header : this.defaultToastParams.header;
      const message = tp.message ? tp.message : this.defaultToastParams.message;
      const duration = tp.duration ? tp.duration : this.defaultToastParams.duration;
      const cssClass = tp.cssClass ? tp.cssClass : this.defaultToastParams.cssClass;

      buttons.push(
        { side: 'start', icon: `/assets/icons/status/${cssClass}-icon.svg` },
        { side: 'end', icon: 'close' }
      );

      const toastOptions: ToastOptions = {
        header,
        message,
        duration,
        cssClass,
        buttons
      };

      const toast = await this.toast?.create(toastOptions);
      await toast?.present();
      if (waitDismiss) {
        await toast.onWillDismiss();
      }
      resolve(false);
    });
  }

  // TODO: Remove presentToast

  async presentToast(message, ok = false, duration = 5000): Promise<void> {
    if (this.toast) {
      this.toast.onDidDismiss();
    }

    this.toast = await this.toastCtrl.create({
      message,
      duration: ok ? null : duration,
      position: 'bottom',
      buttons: ['X']
    });

    await this.toast.present();
  }

  /*========================================================================*\
   * Storage
  /*========================================================================*/

  async checkFirstVisit(): Promise<void> {
    const today: Date = new Date();
    const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    const key = 'statistics_first_visit';
    await this.storageService.setItem(key, {
      date,
      visited: true
    });
  }

  async getKey(key: string): Promise<void> {
    return await this.storage.get(key);
  }

  /*========================================================================*\
   * Count in/correct questions
  /*========================================================================*/

  /**
   * @param(num). 1 -> correct, 2 -> incorrect
   */
  countResultQuestions(obj: any, num: number): number {
    const arrValues: any[] = [];

    Object.keys(obj).forEach((key) => {
      if (obj[key] === num) {
        arrValues.push((obj[key]));
      }
    });

    return arrValues.length;
  }

  /*========================================================================*\
   * Count completed questions
  /*========================================================================*/

  countCompletedQuestions(obj: any): number {
    const arrValues: any[] = [];

    Object.keys(obj).forEach((key) => {
      if (obj[key] !== 0) {
        arrValues.push((obj[key]));
      }
    });

    return arrValues.length;
    // return (obj).filter((val: number) => val !== 0).length;
  }

  /*========================================================================*\
   * Filter in/correct questions
  /*========================================================================*/

  /**
   * @param(num). 1 -> correct, 2 -> incorrect
   */
   filterResultQuestions(obj: any, num: number): number[] {
    const arrValues: any[] = [];

    Object.keys(obj).forEach((key) => {
      if (obj[key] === num) {
        const getNumberKey: string = key.split('question_')[1];
        arrValues.push((getNumberKey));
      }
    });

    return arrValues;
  }

  /*========================================================================*\
   * Modals
  /*========================================================================*/

  async showModal(params: ModalParams | any = this.defaultModalParams, waitResponse = false) {
    const modalConfig = await this.modalCtrl.create({
      component: params.component,
      cssClass: params.cssClass ? params.cssClass : this.defaultModalParams.cssClass,
      componentProps: params.componentsProps ? params.componentsProps : this.defaultModalParams.componentsProps,
      presentingElement: params.presentingElement ? params.presentingElement : null,
      swipeToClose: params.swipeToClose ? params.swipeToClose : this.defaultModalParams.swipeToClose
    });

    await modalConfig.present();

    if (waitResponse) {
      return await modalConfig.onDidDismiss();
    }

    return;
  }

  updateActiveModals() {
    this.storage.get('student_active_modals').then(async (res) => {
      if (res) {
        this.globalVarsService.active_modals = JSON.parse(res);
      }

      await this.saveActiveModals();
    });
  }

  async saveActiveModals() {
    await this.storage.create();
    this.storage.set('student_active_modals', JSON.stringify(this.globalVarsService.active_modals));
  }

  /*========================================================================*\
   * Download
  /*========================================================================*/

  updateUnitsDownloaded() {
    this.storage.get('student_units_downloaded').then((res) => {
      if (res) {
        this.globalVarsService.units_downloaded = JSON.parse(res);
      }

      this.saveUnitsDownloaded();
    });
  }

  saveUnitsDownloaded() {
    this.storage.set('student_units_downloaded', JSON.stringify(this.globalVarsService.units_downloaded));
  }

  /*========================================================================*\
   * Confetti
  /*========================================================================*/

  runConfettiEffect(ref: any, obj: { [key: string]: any }): any {
    const canvas: string = this.renderer2.createElement('canvas');
    this.renderer2.appendChild(ref, canvas);

    const myConfetti: any = confetti.create(canvas, {
      resize: true
    });

    myConfetti(obj);
  }

  /*========================================================================*\
   * Camera
  /*========================================================================*/

  async selectPhoto(small = true) {
    try {
      const options: any = {
        quality: 80,
        targetWidth: 500,
        //destinationType: Camera.DestinationType.FILE_URI,
        //encodingType: Camera.EncodingType.JPEG,
        //mediaType: Camera.MediaType.PICTURE,
        saveToPhotoAlbum: true
      };

      const imageData: Photo = await Camera.getPhoto(options);

      if (imageData) {
        const base64Image: string = 'data:image/jpeg;base64,' + imageData;
        console.log('image data: ', imageData);
        return base64Image;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /*========================================================================*\
   * Back
  /*========================================================================*/

  back(path?: string, params = {}): void {
    this.play('back');
    this.router.navigate([path], params);
  }

  /*========================================================================*\
   * Visibility Menu
  /*========================================================================*/

  hideMenu(hide = true): void {
    const bodyEl = document.body.classList;
    if (hide) {
      bodyEl.add('menu-is-hidden');
    } else {
      bodyEl.remove('menu-is-hidden');
    }
  }

  /*========================================================================*\
   * Generate Random UUID
  /*========================================================================*/

  generateRandomUUID(length = 15): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log('result',result);
    return result;
  }

}
