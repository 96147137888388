import { Injectable } from '@angular/core';

import { TimeagoClock, TimeagoCustomFormatter } from 'ngx-timeago';
import { Observable, interval } from 'rxjs';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})

export class TimeAgoClock extends TimeagoClock {
  tick(then: number): Observable<number> {
    return interval(60000);
  }
}

@Injectable({
  providedIn: 'root',
})
export class  TimeAgoFormatter extends TimeagoCustomFormatter {
  override format(then: number): string {
    if (moment().diff(moment(then), 'day') > 7) {
      return capitalizeWord(moment(then).locale('es').format('DD MMMM, YYYY'));
    } else {
      return super.format(then);// capitalizeFirstLetter(super.format(then));
    }
  }
}

function capitalizeWord(string: string) {
  return string.toLowerCase()
    .split(' ')
    .map(capitalizeFirstLetter)
    .join(' ');
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.substring(1);
}
