/* eslint-disable @typescript-eslint/naming-convention */
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { strings as spanishStrings } from "ngx-timeago/language-strings/es";
import { TimeagoIntl } from "ngx-timeago";
import { CodePush } from '@awesome-cordova-plugins/code-push/ngx';
import { Push, PushObject, PushOptions } from '@awesome-cordova-plugins/push/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { App } from '@capacitor/app';

import { Profile } from '@interfaces/profile';
import { ApiService } from '@services/api/api.service';
import { CronService } from '@services/cron/cron.service';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { ProgressService } from '@services/progress/progress.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils.service';
import { PurchasesService } from '@services/purchases/purchases.service';
import {MetaSeoService} from "@services/meta-seo/meta-seo.service";
import {filter, map, mergeMap, tap} from "rxjs/operators";
import { WebSocketService } from '@services/shared/websocket.service';
import { SessionSocketService } from '@services/session/session-socket.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('content') content: any;

  rootPage: any;
  backAlert = true;
  fromPush = false;
  profile: Profile;

  constructor(
    public utilsService: UtilsService,
    private alertCtrl: AlertController,
    private menuCtrl: MenuController,
    private codePush: CodePush,
    private cronService: CronService,
    private platform: Platform,
    private push: Push,
    private router: Router,
    private screenOrientation: ScreenOrientation,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private apiService: ApiService,
    private globalVarsService: GlobalVarsService,
    private progressService: ProgressService,
    private purchasesService: PurchasesService,
    private storage: Storage,
    private storageService: StorageService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private metaSeoService: MetaSeoService,
    private intl: TimeagoIntl,
    private sessionService:  SessionSocketService,
    private websocketService: WebSocketService
  ) { }

  @HostListener('click', ['$event'])
  async onClick(event: any) {
    const res: any = await this.cronService.compare();
    if (res) {
      this.logout();
    }
  }

  async ngOnInit(): Promise<void> {
    this.websocketService.connect();
    await this.storage.create();
    this.platform.resume.subscribe(() => {
      this.codePush.sync();
    });

    this.router.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
              while (route.firstChild) {
                route = route.firstChild;
              }
              return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data),
            tap(({title,description}: Data) => {
              this.metaSeoService.updateTitle(title);
              this.metaSeoService.updateDescription(description);
            })
        ).subscribe();

    const isReady = await this.platform.ready();
    if (isReady) {
      this.statusBar.styleBlackTranslucent();
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(true);
      this.statusBar.hide();
      this.splashScreen.hide();

      if (this.platform.is('cordova')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }

      /*const splash = document.getElementById('splash-screen');
      if (splash) {
        splash.classList.add('on');

        setTimeout(() => {
          this.utilsService.play('splash');
        }, 2000);

        setTimeout(() => {
          splash.remove();
        }, 5000);
      }*/

      this.utilsService.loadDefaultSounds();
      this.utilsService.loadRandomSounds();

      this.initTranslate();

      this.platform.backButton.subscribe(async () => {

        if (!this.backAlert) {
          this.backAlert = true;

          const alert = await this.alertCtrl.create({
            header: this.translateService.instant('alert.close_app_title'),
            subHeader: this.translateService.instant('alert.close_app_message'),
            buttons: [
              {
                text: this.translateService.instant('alert.cancelar'),
                role: 'cancel',
                handler: () => {
                  this.backAlert = false;
                  console.log('Salida de app cancelada');
                },
              },
              {
                text: this.translateService.instant('alert.close_app_exit'),
                handler: () => {
                  App.exitApp();
                },
              },
            ],
          });

          await alert.present();
        } else {
          this.router.navigate(['/home']);
        }
      });

      this.getSettings();

      await this.getCurrentProfile();
    };

    this.utilsService.codeSync();
  }

  setInitialLanguage() {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  goTo(path: string, type?: any): void {
    this.utilsService.play('ok');

    if (type) {
      this.router.navigate([path], { queryParams: { type } });
    } else {
      this.router.navigate([path]);
    }

    this.menuCtrl.close();
  }

  async getSettings() {
    const student_active_modals: any = await this.utilsService.getKey('student_active_modals');

    if (student_active_modals !== null) {
      this.globalVarsService.active_modals = JSON.parse(student_active_modals);
    }

    const student_progress: any = await this.utilsService.getKey('student_progress');

    if (student_progress !== null) {
      this.progressService.progressUser = JSON.parse(student_progress);
    }

    const student_units_downloaded: any = await this.utilsService.getKey('student_units_downloaded');

    if (student_units_downloaded !== null) {
      this.progressService.progressUser = JSON.parse(student_units_downloaded);
    }

    const student_token: any = await this.utilsService.getKey('student_token');

    if (student_token !== null) {
      this.globalVarsService.student_token = student_token;
    }

    console.log('student_token: ' + this.globalVarsService.student_token);

    await this.checkStudent();

    //Inicio notificaciones
    this.initPushNotification();
  }

  async checkStudent(): Promise<void> {
    if (this.globalVarsService.student_token) {
      if (this.platform.is('cordova')) {
        console.log('IAP ::: initIap from app.component.ts');
        this.purchasesService.initIap();
        setInterval(() => {
          this.purchasesService.refresh();
        }, 30000);
      }
    }
  }

  initPushNotification(): void {
    if (!this.platform.is('cordova')) {
      console.warn('Push notifications not initialized. Cordova is not available - Run in physical device');
      return;
    }

    const options = {
      android: {
        forceShow: 'true',
      },
      ios: {
        alert: 'true',
        badge: 'true',
        sound: 'true',
      },
    };

    const pushObject = this.push.init(options);

    pushObject.on('registration').subscribe(async (data: any) => {
      console.log('device token -> ' + data.registrationId);

      this.globalVarsService.fcm_token = data.registrationId;

      data = {
        fcm_token: data.registrationId,
        platform: this.platform.is('android') ? 'android' : 'ios',
      };

      console.log(JSON.stringify(data));

      const resp: any = await this.apiService.pushRegister(data);
      if (resp) {
        console.log(resp);
      }

    });

    if (this.platform.is('android')) {
      this.push
        .createChannel({
          id: 'english4steps',
          description: 'Notificaciones',
          importance: 4,
        })
        .then(() => console.log('Channel created'));
    }

    pushObject.on('notification').subscribe((data: any) => {
      this.fromPush = true;

      console.log('FCM MESSAGE: ' + JSON.stringify(data));
    });

    pushObject.on('error').subscribe((error) => console.error('Error with Push plugin' + error));
  }

  initTranslate() {
    this.translateService.use('en');
    this.intl.strings = spanishStrings;
    this.intl.changes.next();
  }

  closeMenu(): void {
    this.menuCtrl.close();
  }

  async logout(): Promise<void> {
    this.menuCtrl.close();
    await this.storageService.clear();
    await this.utilsService.checkFirstVisit();
    this.globalVarsService.student_token = '';
    this.progressService.progressUser = this.progressService.progressUserDefault;
    this.globalVarsService.active_modals.intro = true;
    this.sessionService.endSession();
    this.utilsService.saveActiveModals();
    this.router.navigate(['/login']);
  }

  private async getCurrentProfile(): Promise<void> {
    const resp: any = await this.apiService.getChatProfile();
    if (resp) {
      this.profile = resp.body;
    }
  }

}
