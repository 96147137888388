import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { ApiService } from '@services/api/api.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-modal-winner-coin',
  templateUrl: './modal-winner-coin.component.html',
  styleUrls: ['./modal-winner-coin.component.scss'],
})
export class ModalWinnerCoinComponent implements OnInit {

  public showMessage = true;

  constructor(
    public utilsService: UtilsService,
    private location: Location,
    private modalCtrl: ModalController,
    private router: Router,
    private apiService: ApiService,
    private storageService: StorageService,
  ) {
    this.utilsService.play('coin-win', 0.5);
    this.updateCoins();
  }

  ngOnInit(): void {
    const videoEl = document.querySelector('video');
    if (videoEl) {
      videoEl.play();
    }
    setTimeout(() => this.checkProvider(), 3000);
  }

  async checkProvider(): Promise<void> {
    const checkPopup: any = await this.storageService.getItem('openFirstCoinModal');
    if (!checkPopup) {
      this.storageService.setItem('openFirstCoinModal', true);
    }
  }

  goTo(): void {
    this.utilsService.play('ok');
    this.modalCtrl.dismiss();
    this.showMessage = false;
    this.router.navigate(['/coins']);
  }

  ionViewWillLeave() {
    this.utilsService.play('back');
  }

  private async updateCoins(): Promise<void> {
    const resp = await this.apiService.updateCoins();
    if (resp) {
      console.log('Update coins: ', resp);
    }
  }

}
