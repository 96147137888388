import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';

import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private globalVarsService: GlobalVarsService,
    private storageService: StorageService
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise(
      async resolve => {
        const token: any = await this.storageService.getAccessToken();
        console.log('Student Token: ', token);
        console.log('In Auth guard');
        console.log('CurrentUrl: ', this.router.url);

        if (token) {
          resolve(true);
          /*const activeModals: any = await this.storageService.getItem('student_active_modals');
          if (activeModals) {
            const activeModalsParsed = JSON.parse(activeModals);
            if (!activeModalsParsed.free_trial && !this.globalVarsService.subscribed) {
              this.router.navigate(['/free-trial']);
              resolve(false);
            } else {
              const firstVisit: any = await this.storageService.getItem('statistics_first_visit');
              if (!firstVisit) {
                this.router.navigate(['/profile/statistics']);
                resolve(false);
              } else {
                resolve(true);
              }
            }
          }*/
        } else {
          this.router.navigate(['/login']);
          resolve(false);
        }
      }
    );
  }

}
