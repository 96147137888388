import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-header-information',
  templateUrl: './header-information.component.html',
  styleUrls: ['./header-information.component.scss'],
})
export class HeaderInformationComponent implements OnInit {

  @Input() contentID: string;

  constructor(
    private menuCtrl: MenuController,
    private router: Router
  ) { }

  ngOnInit() {}

  async openMenu(): Promise<void> {
    const menuId = this.contentID;
    const isEnabled: boolean = await this.menuCtrl.isEnabled(menuId);
    if (!isEnabled) {
      await this.menuCtrl.enable(true, menuId);
    }
    await this.menuCtrl.open(menuId);
    // this.menuCtrl.open(this.contentID);
  }

  closeMenu(): void {
    this.menuCtrl.close(this.contentID);
  }

  goTo(path: string): void {
    this.closeMenu();
    this.router.navigate([path]);
  }

}
