import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ModalAllPaymentBothComponent } from '@components/modal/payment/modal-all-payment-both/modal-all-payment-both.component';
import { User } from '@interfaces/user';
import { ApiService } from '@services/api/api.service';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-modal-info-check-subscription',
  templateUrl: './modal-info-check-subscription.component.html',
  styleUrls: ['./modal-info-check-subscription.component.scss'],
})
export class ModalInfoCheckSubscriptionComponent implements OnInit {

  public code = 123456;
  public user: User;

  constructor(
    public utilsService: UtilsService,
    private modalCtrl: ModalController,
    private apiService: ApiService,
    private globalVarsService: GlobalVarsService,
    ) { }

  async ngOnInit() {
    await this.getUser();
  }

  async getUser(): Promise<void> {
    const profile: { [key: string]: any } = await this.apiService.getProfile();

    if (profile) {
      this.user = profile.body;
    }
  }

  async allPayment(): Promise<void> {
    if (this.globalVarsService.subscribed) {
      return;
    }

    const modal = await this.utilsService.showModal({
      component: ModalAllPaymentBothComponent,
      cssClass: 'all-payment-modal',
    });

    if (modal.data) {
      console.log('all-payment-modal', modal.data);
    }
  }

  checkCode() {
    console.log('check code.');
  }

  closeModal(): void {
    this.utilsService.play('back');
    this.modalCtrl.dismiss();
  }

}
