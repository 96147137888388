import { NgModule } from '@angular/core';

import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '@pipes/pipes.module';


@NgModule({
  declarations: [],
  imports: [
    DirectivesModule,
    PipesModule
  ],
  exports: [
    DirectivesModule,
    PipesModule
  ]
})
export class SharedModule { }
