import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Component({
  selector: 'app-button-enroll',
  templateUrl: './button-enroll.component.html',
  styleUrls: ['./button-enroll.component.scss'],
})
export class ButtonEnrollComponent implements OnInit {

  public showButton = true;
  public hasExpiredPurchases = false;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.checkAllowPurchase();
  }

  private async checkAllowPurchase(): Promise<void> {
    const resp: any = await this.apiService.allowWebPurchase();
    if (resp) {
      this.showButton = resp.body.status;
      this.checkExpiredPurchases();
    }
  }

  private async checkExpiredPurchases(): Promise<void> {
    const resp: any = await this.apiService.checkExpiredPurchases();
    if (resp) {
      this.hasExpiredPurchases = resp.body.expiredPurchases;
    }
  }

}
