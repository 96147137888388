/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { CheckAllowPurchaseGuard } from '@guards/check-allow-purchase.guard';
import { CheckPremiumAccessGuard } from '@guards/check-premium-access.guard';
import { IntroGuard } from '@guards/intro.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'presentation',
    pathMatch: 'full',
  },
 {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule),
    // canActivate: [IntroGuard]
     data: {
         title: "Accede a tu cuenta de English in 4 Steps - Wellington-Academy.com",
         description: "Accede a tu cuenta del curso de English in 4 Steps en Wellington-Academy.com"
     }
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule),
      data: {
          title: "Prueba gratis English in 4 Steps - Wellington-Academy.com",
          description: "Prueba gratis el curso English in 4 Steps en Wellington-Academy.com antes de matricularte."
      }
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./pages/auth/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: 'coins',
    loadChildren: () => import('./pages/coins/coins/coins.module').then( m => m.CoinsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tpv',
    loadChildren: () => import('./pages/enroll/enroll/enroll.module').then( m => m.EnrollPageModule),
    canActivate: [AuthGuard]
  },
    {
        path: 'infoTpv',
        loadChildren: () => import('./pages/enroll/first-enroll/first-enroll.module').then( m => m.FirstEnrollPageModule),
        canActivate: [AuthGuard]
    },
  {
    path: 'tpv-level',
    loadChildren: () => import('./pages/enroll/enroll-level/enroll-level.module').then( m => m.EnrollLevelPageModule),
    canActivate: [AuthGuard, CheckAllowPurchaseGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
  },
  {
    path: 'questions',
    loadChildren: () => import('./pages/questions/questions.module').then( m => m.QuestionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'random',
    loadChildren: () => import('./pages/random/random/random.module').then( m => m.RandomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'phonetics',
    loadChildren: () => import('./pages/phonetics/phonetics.module').then( m => m.PhoneticsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/levels/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'levels',
    loadChildren: () => import('./pages/levels/levels/levels/levels.module').then( m => m.LevelsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'passport',
    loadChildren: () => import('./pages/levels/passport/passport/passport.module').then( m => m.PassportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tutoring',
    loadChildren: () => import('./pages/tutoring/tutoring.module').then( m => m.TutoringPageModule)
  },
  {
    path: 'help-center',
    loadChildren: () => import('./pages/help-center/help-center.module').then( m => m.HelpCenterPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/legal/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'general-terms',
    loadChildren: () => import('./pages/legal/general-terms/general-terms.module').then( m => m.GeneralTermsPageModule)
  },
  {
    path: 'app-movil',
    loadChildren: () => import('./pages/app-movil/app-movil.module').then( m => m.AppMovilPageModule),
    canActivate: [CheckPremiumAccessGuard],
  },
  {
    path: 'colourphonics',
    loadChildren: () => import('./pages/colour-phonics/colour-phonics.module').then( m => m.ColourPhonicsPageModule),
      data: {
          title: "ColourPhonics® - English Phonetics made easy - English in 4 Steps - Wellington-Academy.com",
          description: "La fonética fácil y divertida. Todos los cursos de Wellington Academy integran el estudio de fonética con ColourPhonics®"
      }
  },
  {
    path: 'professional-life',
    loadChildren: () => import('./pages/professional-life/professional-life.module').then( m => m.ProfessionalLifePageModule),
      data: {
          title: "Inglés para tu vida profesional - English in 4 Steps - Wellington-Academy.com",
          description: "Gestión de Créditos FUNDAE para la formación de empresas. Especialistas en cursos bonificables de Inglés e Inteligencia Artificial."
      }
  },
  {
    path: 'presentation',
    loadChildren: () => import('./pages/presentation/presentation.module').then( m => m.PresentationPageModule),
      data: {
          title: "English in 4 Steps - Wellington-Academy.com",
          description: "Aprende inglés online con un método revolucionario que enfatiza la fonética para lograr que domines completamente el Speaking y el Listening. Todos los niveles desde el A1 al C1."
      }
  },
  {
    path: 'professional',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule),
    data: { type: 'professional' },
    canActivate: [AuthGuard]
  },
  {
    path: 'extra',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule),
    data: { type: 'extra' },
    canActivate: [AuthGuard]
  },
  {
    path: 'conversation',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule),
    data: { type: 'conversacion' },
    canActivate: [AuthGuard]
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'unlock',
    loadChildren: () => import('./pages/levels/units/unlock/unlock.module').then( m => m.UnlockPageModule),
    data: { type: 'coupon' },
    canActivate: [CheckAllowPurchaseGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
