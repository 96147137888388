import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CronService {

  public initDate = new Date();
  private readonly limitSession = environment.cron;

  constructor(
    private storageService: StorageService
  ) { }

  async compare(): Promise<boolean> {
    const token: any = await this.storageService.getAccessToken();
    if (token) {
      const currentDate = new Date();
      let difference = (currentDate.getTime() - this.initDate.getTime()) / 1000;
          difference /= 60;
      const calculateTime = Math.abs(Math.round(difference));
      console.log('Calculated time: ', calculateTime);
      if (calculateTime > this.limitSession) {
        return true;
      } else {
        this.initDate = currentDate;
        return false;
      }
    }
  }
}
