/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { Profile } from '@interfaces/profile';
import { ApiService } from '@services/api/api.service';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { ProgressService } from '@services/progress/progress.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils.service';
import { WebSocketService } from '@services/shared/websocket.service';
import { SessionSocketService } from '@services/session/session-socket.service';
import { ChatCommunicationService } from '@services/chat/chat-communication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {

  @ViewChild('content') content: any;

  public currentType: string;
  public rootPage: any;
  public backAlert = true;
  public fromPush = false;
  public profile: Profile;
  public showButton = true;
  public hasExpiredPurchases = false;
  public pendingMessagesCount = null;

  private subscriptions: Subscription[]= []; 

  constructor(
    public utilsService: UtilsService,
    private menuCtrl: MenuController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private globalVarsService: GlobalVarsService,
    private progressService: ProgressService,
    private storageService: StorageService,
    private sessionService:  SessionSocketService,
    private chatService: ChatCommunicationService,
  ) { }

  ngOnInit(): void {
    this.checkCurrentRouter();
    this.checkForPendingMessages()
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  goTo(path: string, type?: any): void {
    this.utilsService.play('ok');

    if (type) {
      this.router.navigate([path], { queryParams: { type } });
    } else {
      this.router.navigate([path]);
    }

    this.menuCtrl.close();
  }

  closeMenu(): void {
    this.menuCtrl.close('menu');
  }

  async logout(): Promise<void> {
    this.menuCtrl.close();
    await this.storageService.clear();
    await this.utilsService.checkFirstVisit();
    this.globalVarsService.student_token = '';
    this.progressService.progressUser = this.progressService.progressUserDefault;
    this.globalVarsService.active_modals.intro = true;
    this.sessionService.endSession();
    this.utilsService.saveActiveModals();
    this.router.navigate(['/login']);
  }

  private checkCurrentRouter(): void {
    const routerSubscription = this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentType = null;
        this.checkAllowPurchase();
        // this.checkPurchases();
      }

      if (event instanceof NavigationEnd) {
        const params: Params = await this.activatedRoute.queryParams
                                         .pipe(take(1))
                                         .toPromise();

        if (params.type) {
          this.currentType = params.type;
        } else {
          if (event.url.includes('home')) {
            this.currentType = 'general';
            return;
          }

          this.currentType = event.url.replace('/','');
        }
      }
    });
    this.subscriptions.push(routerSubscription)
  }

  private async checkAllowPurchase(): Promise<void> {
    const resp: any = await this.apiService.allowWebPurchase();
    if (resp) {
      this.showButton = resp.body.status;
      this.checkExpiredPurchases();
    }
  }

  private async checkExpiredPurchases(): Promise<void> {
    const resp: any = await this.apiService.checkExpiredPurchases();
    if (resp) {
      console.log('check expired purchases: ', resp.body);
      this.hasExpiredPurchases = resp.body.expiredPurchases;
    }
  }

  private async checkForPendingMessages() {
    const pendingMessagesSub = this.chatService.getPendingMessagesCount().subscribe((count) => {
      this.pendingMessagesCount = count;
      // console.log('pendingMessagesCount', count)
    });
    this.subscriptions.push(pendingMessagesSub);
    this.chatService.checkForPendingMessages();
  }

}
