import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {

  constructor(
      private translate: TranslateService
  ){ }

  transform(value: any): string {
    if (!value) {
        return this.translate.instant('date.aLongTimeAgo');
    }

    const isYesterday: boolean = this.compareDates(value);
    if (isYesterday) {
        return this.translate.instant('date.yesterday');
    }

    let time: number = (Date.now() - Date.parse(value)) / 1000;
    if (time < 10) {
      return this.translate.instant('date.justNow');
    } else if (time < 60) {
      return this.translate.instant('date.aMomentAgo');
    }

    const divider: number[] = [60, 60, 24, 30, 12];

    const translateDate: { [key: string]: string } = this.translate.instant('date');
    const options: string[] = [
      translateDate.second,
      translateDate.minute,
      translateDate.hour,
      translateDate.day,
      translateDate.month,
      translateDate.year
    ];

    let i = 0;
    for (i; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }

    const plural: string = Math.floor(time) > 1 ? 's' : '';
    return Math.floor(time) + ' ' + options[i] + plural;
  }

  private compareDates(value: any): boolean {
    const yesterday: Date = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const dateLastMessage: string = this.convertDate(value);
    const dateYesterday: string = this.convertDate(yesterday);

    return (dateLastMessage === dateYesterday);
  }

  private convertDate(value: any): string {
    return value; 
    // TODO: Debug
    // return value.getUTCDate() + '-' + (value.getUTCMonth() + 1) + '-' + (value.getUTCFullYear());
  }
}