import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { PurchasesService } from '@services/purchases/purchases.service';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-modal-all-payment-both',
  templateUrl: './modal-all-payment-both.component.html',
  styleUrls: ['./modal-all-payment-both.component.scss'],
})
export class ModalAllPaymentBothComponent implements OnInit {

  isAndroid = false;
  platformName: any;
  productId: string;

  constructor(
    public utilsService: UtilsService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private globalVarsService: GlobalVarsService,
    private purchasesService: PurchasesService,
  ) { }

  ngOnInit(): void {
    this.isAndroid = this.platform.is('android');
    this.platformName = this.isAndroid ? 'android' : 'ios';
  }

  closeModal(): void {
    this.utilsService.play('back');
    this.modalCtrl.dismiss();
  }

  purchaseAllPayment(monthly: number): void {
    console.log(monthly);
    this.productId = this.globalVarsService.products[this.platformName].monthly;
    this.purchasesService.buyAll(this.modalCtrl);
  }

  purchaseAllMonthlyPayment(): void {
    this.productId = this.globalVarsService.products[this.platformName].monthly;
    this.purchasesService.buyAllMonthly(this.modalCtrl);
  }

}
