import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icons-shortcuts',
  templateUrl: './icons-shortcuts.component.html',
  styleUrls: ['./icons-shortcuts.component.scss'],
})
export class IconsShortcutsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
