/* eslint-disable no-underscore-dangle */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/no-input-rename */

import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[passwordToggle]' // Attribute selector
})
export class PasswordToggleDirective {

    @Input('appTargetInput') targetInput: any;

    constructor(
        public el: ElementRef,
        public renderer: Renderer2
    ) { }

    @HostListener('click') onMouseEnter() {

        const inType = (this.targetInput.type === 'text') ? 'password' : 'text';

        this.targetInput.type = inType;

        if (inType === 'text') {
            this.renderer.removeClass(this.el.nativeElement, 'password');
            this.renderer.addClass(this.el.nativeElement, 'text');
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'text');
            this.renderer.addClass(this.el.nativeElement, 'password');
        }

    }

}
