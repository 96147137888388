import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { WebSocketService } from '@services/shared/websocket.service';

@Injectable({
  providedIn: 'root',
})
export class SessionSocketService {

  constructor(
    private websocketService: WebSocketService,
    private apiService: ApiService,
  ) {
    this.initializeWebSocketListeners();
  }

  private initializeWebSocketListeners() {
    this.websocketService.onOpen().subscribe(event => {
      //console.log('WebSocket conectado:', event);
    });

    this.websocketService.onClose().subscribe(event => {
      //console.log('WebSocket desconectado:', event);
      this.handleReconnection();
    });

    this.websocketService.onError().subscribe(event => {
      //console.error('Error en WebSocket:', event);
      this.handleReconnection();
    });
  }

  private handleReconnection() {
    setTimeout(() => {
       this.websocketService.reconnect();
    }, 5000); // Intentar reconectar cada 5 segundos
  }

  async startSession() {
    try {
      const resp: any = await this.apiService.getProfile();
      if (resp) {
        const user = resp.body;
        localStorage.setItem('student_id', '' + user.id);
        const userId = user.id;
        const userIP = await this.websocketService.getUserIP();
        this.websocketService.sendMessage({ type: 'login', studentId: userId, ip: userIP });
      } else {
        //console.log('Error obteniendo id de usuario');
      }
    } catch (error) {
      //console.error('Error iniciando sesión:', error);
    }
  }

  


  endSession() {
    localStorage.removeItem('session')
    localStorage.removeItem('student_id')
    this.websocketService.sendMessage({ type: 'logout' });
    //console.log('Usuario deslogueado.');
  }

  listenToSessionEvents(callback: (data: any) => void) {
    this.websocketService.onMessage('sessionEvent', callback);
  }
}
