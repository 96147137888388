import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-modal-level-finish',
  templateUrl: './modal-level-finish.component.html',
  styleUrls: ['./modal-level-finish.component.scss'],
})
export class ModalLevelFinishComponent implements OnInit {

  constructor(
    public utilsService: UtilsService,
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.utilsService.play('passport-win', 0.5);
  }

  closeModal(redirect = false): void {
    this.utilsService.play('ok');
    this.modalCtrl.dismiss();
    if (redirect) {
      this.router.navigate(['/passport/levels']);
    }
  }

  ionViewWillLeave() {
    this.utilsService.play('back');
  }

}
