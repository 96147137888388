/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Auth } from '@interfaces/auth';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  public isFormPaypalData = false;
  private readonly basePath = environment.serverPath;

  constructor(
    private http: HttpClient,
    private globalVarsService: GlobalVarsService,
    private storageService: StorageService
  ) { }

  postDataAuth(userData: Auth, url: string): Promise<HttpResponse<object>> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });

      this.http
          .post(this.basePath + url, userData, { headers, observe: 'response' })
          .subscribe(
            (data) => resolve(data),
            (err) => reject(err)
          );
    });
  }

  async postData(data: any = {}, url: string): Promise<HttpResponse<object>> {
    const token: any = await this.storageService.getAccessToken();
    if (token) {
      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        });

        this.http
            .post(this.basePath + url, data, { headers, observe: 'response' })
            .subscribe(
              (resp) => resolve(resp),
              (err) => reject(err)
            );
      });
    }
  }

  async getData(url: string): Promise<HttpResponse<object>> {
    const token: any = await this.storageService.getAccessToken();
    if (token) {
      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        });

        this.http
            .get(this.basePath + url, { headers, observe: 'response' })
            .subscribe(
              (resp) => resolve(resp),
              (err) => reject(err)
            );
      });
    }
  }

  postDataAsync(data: any = {}, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers: HttpHeaders | any = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: ' ' + this.globalVarsService.student_token,
      };

      this.http.post(this.basePath + url, data, { headers }).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

}
