import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { UtilsService } from '@services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseInterceptor implements HttpInterceptor {

    isRetried = false;

    constructor(
        private toastCtrl: ToastController,
        private translateService: TranslateService,
        private utilsService: UtilsService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            //finalize(() => this.utilsService.hideLoader()),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                if (error && error.error && error.error.message) {
                if (Array.isArray(error.error.message)) {
                    const joinMessage = error.error.message.join(', ');
                    this.utilsService.showToast({
                    message: `${ this.translateService.instant('general.problems_points') } ${joinMessage}`,
                    cssClass: 'danger',
                    header: this.translateService.instant('general.error_ocurred'),
                    duration: 10000
                    });
                } else {
                    this.utilsService.showToast({
                        message: error.error.message,
                        cssClass: 'danger',
                        header: this.translateService.instant('general.error_ocurred')
                    });
                }
                } else {
                this.utilsService.showToast({
                    message: this.translateService.instant('general.error_try'),
                    cssClass: 'danger',
                    header: this.translateService.instant('general.error_ocurred')
                });
                }

                return throwError(error);
            })
        );
    }

}
