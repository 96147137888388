/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '@services/api/api.service';
import { UtilsService } from '@services/utils/utils.service';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import {BehaviorSubject} from "rxjs";

const iap = InAppPurchase2;

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  public validationEnd = false;
  public initiated = false;
  public buyAtProccess = false;
  public fromAllPaymentButton = false;
  public userMsg = '';
  public os = '';
  public handlers: any = [];
  public levelData: any = false;
  // public exViewCtrl: ViewController;

  public unionCode$$ = new BehaviorSubject<string>(undefined)
  public unionCode$ = this.unionCode$$.asObservable()

  constructor(
    public utilsService: UtilsService,
    private platform: Platform,
    private globalVarsService: GlobalVarsService,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {
    this.os = this.platform.is('android') ? 'android' : 'ios';
    console.log('Hello PurchasesService Service');
  }

  initIap() {
    if (!this.initiated) {
      console.log('IAP ::: initIap');

      iap.verbosity = iap.DEBUG;
      iap.error((e) => console.log('IAP ::: error ' + JSON.stringify(e)));

      for (const key in this.globalVarsService.products[this.os]) {
        if (this.globalVarsService.products[this.os].hasOwnProperty(key)) {
          const id = this.globalVarsService.products[this.os][key];
          console.log('IAP ::: registrado ' + key);
          if (key === 'all') {
            iap.register({
              id,
              alias: id,
              type: iap.PAID_SUBSCRIPTION,
            });
          } else {
            iap.register({
              id,
              alias: id,
              type: iap.CONSUMABLE,
            });
          }

          const handlers = [];

          handlers.push(
            iap.when(id).approved(async (p) => {
              console.log('IAP ::: ' + p.id + ' approved ' + JSON.stringify(p));
              let transaction_id = 0;
              if (p.hasOwnProperty('transaction')) {
                transaction_id = p.transaction.id;
              }
              //p.verify();
              p.finish();

              if (id === this.globalVarsService.products.android.all || id === this.globalVarsService.products.ios.all) {
                // p.finish();
              } else {
                // p.finish();

                if (this.levelData && transaction_id) {
                  await this.utilsService.showLoader();
                  this.levelData.transaction_id = transaction_id;

                  const resp: any = await this.apiService.getPurchase(this.levelData);
                  if (resp) {
                    const data = resp.body;
                    let alert_title: string;
                    let alert_message: string;

                    if (data.status) {
                      // this.exViewCtrl.dismiss();
                      /*let nav = this.app.getActiveNav();
                          nav.push('UnitsPage', { level_id: this.levelData.level_id, level_nunits: this.levelData.level_nunits });*/
                      alert_title = this.translateService.instant('purchases.buy_success');

                      if (this.levelData.type === 'first_half_level') {
                        alert_message = this.translateService.instant('purchases.unlock_first');
                      } else if (this.levelData.type === 'second_half_level') {
                        alert_message = this.translateService.instant('purchases.unlock_second');
                      } else if (this.levelData.type === 'level') {
                        alert_message = this.translateService.instant('purchases.unlock_level');
                      }
                    } else {
                      alert_title = this.translateService.instant('purchases.unlock_error_title');
                      alert_message = this.translateService.instant('purchases.unlock_error_message');
                    }

                    this.levelData = false;
                    await this.utilsService.hideLoader();
                    this.utilsService.presentAlert(alert_title, alert_message);
                  } else {
                    this.levelData = false;
                    await this.utilsService.hideLoader();
                    // console.log('IAP ::: api error ' + JSON.stringify(error));
                  }
                }
              }
            })
          );
          handlers.push(
            iap.when(id).owned(async (p) => {
              console.log('IAP ::: ' + p.id + ' owned ' + JSON.stringify(p));
              console.log('IAP ::: ' + p.id + ' lastRenewalDate ' + p.lastRenewalDate);
              // Guardar
              let transaction_id = 0;
              if (p.hasOwnProperty('transaction')) {
                transaction_id = p.transaction.id;
              }

              if (transaction_id) {
                if (this.fromAllPaymentButton) {
                  await this.utilsService.showLoader();

                  const resp: any = await this.apiService.getPurchase({ type: 'all', transaction_id });
                  if (resp) {
                    const data = resp.body;
                    let alert_title: string;
                    let alert_message: string;

                    if (data.status) {
                      // this.exViewCtrl.dismiss('all');
                      alert_title = this.translateService.instant('purchases.buy_success');
                      alert_message = this.translateService.instant('purchases.unlock_year');
                    } else {
                      alert_title = this.translateService.instant('purchases.unlock_error_title');
                      alert_message = this.translateService.instant('purchases.unlock_error_message');
                    }
                    this.fromAllPaymentButton = false;
                    await this.utilsService.hideLoader();

                    this.utilsService.presentAlert(alert_title, alert_message);
                  } else {
                    this.fromAllPaymentButton = false;
                    await this.utilsService.hideLoader();
                    // console.log('IAP ::: api error ' + JSON.stringify(error));
                  }
                } else {
                  const resp: any = await this.apiService.getPurchase({ type: 'all', transaction_id });
                  if (resp) {
                    const data = resp.body;

                    if (data.status) {
                    } else {
                    }
                  } else {
                    // console.log('IAP ::: api error ' + JSON.stringify(error));
                  }
                }
              }
              this.buyAtProccess = false;
              // this.off(id);
            })
          );
          /*handlers.push(
                iap.when(id).verified((p) => {
                  console.log('IAP ::: ' + p.id + ' verified');
                  this.buyAtProccess = false;
                  p.finish();
                })
              );
              handlers.push(
                iap.when(id).unverified((p) => {
                  console.log('IAP ::: ' + p.id + ' unverified');
                  this.buyAtProccess = false;
                })
              );*/
          handlers.push(
            iap.when(id).cancelled((p) => {
              console.log('IAP ::: ' + p.id + ' cancelled ' + JSON.stringify(p));
              this.buyAtProccess = false;
            })
          );
          handlers.push(
            iap.when(id).updated((p) => {
              console.log('IAP ::: ' + p.id + ' updated ' + JSON.stringify(p));
              if (id === this.globalVarsService.products.android.all || id === this.globalVarsService.products.ios.all) {
                if (p.owned && !p.canPurchase) {
                  this.globalVarsService.subscribed = true;
                } else {
                  this.globalVarsService.subscribed = false;
                }
              }
            })
          );
          handlers.push(
            iap.when(id).expired((p) => {
              console.log('IAP ::: ' + p.id + ' expired ' + JSON.stringify(p));
              // Borrar
              this.buyAtProccess = false;
            })
          );
          if (key !== 'all') {
            handlers.push(
              iap.when(id).valid((p) => {
                console.log('IAP ::: ' + p.id + ' valid ' + JSON.stringify(p));
                // Guardar
                this.buyAtProccess = false;
                // this.off(id);
              })
            );
          }
          handlers.push(
            iap.when(id).invalid((p) => {
              console.log('IAP ::: ' + p.id + ' invalid ' + JSON.stringify(p));
              // Borrar
              this.buyAtProccess = false;
            })
          );
          handlers.push(
            iap.when(id).error((e) => {
              console.log('IAP ::: error ' + JSON.stringify(e));
              this.buyAtProccess = false;
            })
          );
          this.handlers[id] = handlers;
        }
      }

      const status = (stat) => {
        // console.log('IAP ::: ' + JSON.stringify(iap.get(this.productId)));
        console.log('IAP ::: Store is Ready: ' + JSON.stringify(stat));
        console.log('IAP ::: Products: ' + JSON.stringify(iap.products));
      };

      iap.ready(status);
      iap.refresh();
      this.initiated = true;
    }
  }

  buyAll(viewCtrl) {
    this.fromAllPaymentButton = true;
    this.buyAtProccess = true;
   // this.exViewCtrl = viewCtrl;
    console.log('IAP ::: buyAll');

    try {
      const result = iap.order(this.globalVarsService.products[this.os].all);
      if (result) {
        console.log('IAP ::: ' + this.globalVarsService.products[this.os].all + ' buy in progress');
      }
    } catch(err) {
      console.log('IAP ::: error ' + JSON.stringify(err));
    }
  }

  buyAllMonthly(viewCtrl) {
    this.fromAllPaymentButton = true;
    this.buyAtProccess = true;
    // this.exViewCtrl = viewCtrl;
    console.log('IAP ::: buyAllMonthly');

    try {
      const result = iap.order(this.globalVarsService.products[this.os].monthly);
      if (result) {
        console.log('IAP ::: ' + this.globalVarsService.products[this.os].monthly + ' buy in progress');
      }
    } catch(err) {
      console.log('IAP ::: error ' + JSON.stringify(err));
    }
  }

  buyLevel(productId, type, levelId, levelNUnits, viewCtrl) {
    this.buyAtProccess = true;
    this.levelData = {
      type,
      level_id: levelId,
      level_nunits: levelNUnits,
    };
    // this.exViewCtrl = viewCtrl;
    console.log('IAP ::: buyLevel');

    try {
      const result = iap.order(productId);
      if (result) {
        console.log('IAP ::: ' + productId + ' buy in progress');
      }
    } catch(err) {
      console.log('IAP ::: error ' + JSON.stringify(err));
    }
  }

  off(id) {
    this.handlers[id].forEach((handler) => {
      iap.off(handler);
    });
    this.buyAtProccess = false;
    console.log('IAP ::: ' + id + ' off all store callbacks');
  }

  refresh() {
    console.log('IAP ::: refresh');
    iap.refresh();
  }
}
