import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Auth } from '@interfaces/auth';
import { CronService } from '@services/cron/cron.service';
import { RestService } from '@services/rest/rest.service';
import { StorageService } from '@services/storage/storage.service';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { UtilsService } from '@services/utils/utils.service';
import { WelcomeTextComponent } from '@components/welcome-text/welcome-text.component';
import { SessionSocketService } from '@services/session/session-socket.service';
import { WebSocketService } from '@services/shared/websocket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private alertCtrl: AlertController,
    private router: Router,
    private cronService: CronService,
    private globalVarsService: GlobalVarsService,
    private restService: RestService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private modal: ModalController,
    private websocketService: WebSocketService,
    private sessionService:  SessionSocketService
  ) { }

  async doLogin(userData: Auth): Promise<void> {
    try {
      const resp = await this.restService.postDataAuth(userData, 'student/login');
      if (resp) {
        const response: { [key: string]: any } = resp.body;
        if (response.token) {
          this.websocketService.connect();
          await this.storageService.setAccessToken(response.token);
          this.globalVarsService.student_token = response.token;
          localStorage.setItem('student_token',response.token)
          this.cronService.initDate = new Date();
          this.sessionService.startSession();
          await this.utilsService.hideLoader();
          this.router.navigate(['/home']);
        } else {
          let header: string;
          let message: string;

          if (response.attempts_left > 0) {
            header = this.translateService.instant('login.incorrect');
            message = this.translateService.instant('login.attempts', { count: response.attempts_left });
          } else {
            header = this.translateService.instant('login.lock');
            message = this.translateService.instant('login.lockTime');
          }

          const alert: HTMLIonAlertElement = await this.alertCtrl.create({
            header,
            message,
            buttons: [
              {
                text: 'Ok',
                role: this.translateService.instant('user.cancel'),
                handler: () => {},
              },
            ],
          });
          await alert.present();
        }
      }
    } catch (error) {
      console.log('Error: ', error);
      const response = error.error;
      const alert: HTMLIonAlertElement = await this.alertCtrl.create({
        header: this.translateService.instant('login.lock'),
        message: this.translateService.instant('login.unlockTime', { count: response.minutes }),
        buttons: [
          {
            text: 'Ok',
            role: this.translateService.instant('user.cancel'),
            handler: () => {},
          },
        ],
      });
      await alert.present();
    }
  }

  async doRecoveryPassword(data: Auth): Promise<void> {
    try {
      const resp: any = await this.restService.postDataAuth(data, 'student/lost-password');
      if (resp) {
        await this.utilsService.hideLoader();
        const response = resp.body;
        if (response.status) {
          const alert: HTMLIonAlertElement = await this.alertCtrl.create({
            message: this.translateService.instant('init.recover_password_success_message'),
            buttons: [
              {
                text: 'Ok',
                role: this.translateService.instant('user.cancel'),
                handler: () => {
                  this.router.navigate(['/login']);
                },
              },
            ],
          });
          await alert.present();
        } else {
          const alert: HTMLIonAlertElement = await this.alertCtrl.create({
            message: this.translateService.instant('init.recover_password_error_message'),
            buttons: [
              {
                text: 'Ok',
                role: this.translateService.instant('user.cancel'),
                handler: () => {},
              },
            ],
          });
          await alert.present();
        }
      }
    } catch(error) {
      const alert: HTMLIonAlertElement = await this.alertCtrl.create({
        message: this.translateService.instant('init.recover_password_error_message'),
        buttons: [
          {
            text: 'Ok',
            role: this.translateService.instant('user.cancel'),
            handler: () => {},
          },
        ],
      });
      await alert.present();
    }
  }

  async doRegister(userData: Auth): Promise<void> {
    try {
      const resp: any = await this.restService.postDataAuth(userData, 'student/register');
      if (resp) {
        await this.utilsService.hideLoader();
        const response = resp.body;
        if (response.token) {
          await this.storageService.setAccessToken(response.token);
          this.globalVarsService.student_token = response.token;
          this.sessionService.startSession();
          this.router.navigate(['/home']);
          const modal = await this.modal.create({
            component: WelcomeTextComponent,
            cssClass: 'welcome'
          });
        
          await modal.present();
        } else {
          const alert: HTMLIonAlertElement = await this.alertCtrl.create({
            header: 'Error',
            message: 'El nombre de usuario o el correo electrónico que has elegido ya existe en la base de datos, por favor prueba de nuevo. (El nombre de usuario debe ser una sola palabra, si ya existe prueba haciéndolo más específico)',
            buttons: [
              {
                text: 'Ok',
                role: this.translateService.instant('user.cancel'),
                handler: () => {},
              },
            ],
          });
          await alert.present();
        }
      }
    } catch(error) {
      const response = error.error;
      console.error(response);

      const alert: HTMLIonAlertElement = await this.alertCtrl.create({
        header: 'Error',
        message: this.translateService.instant('register.error_message'),
        buttons: [
          {
            text: 'Ok',
            role: this.translateService.instant('user.cancel'),
            handler: () => {},
          },
        ],
      });
      await alert.present();
      await this.utilsService.hideLoader();
    }
  }

  async getToken(): Promise<string> {
    const token: any = await this.storageService.getAccessToken();
    if (token) {
      return token;
    }
  }

}
