import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from '@services/auth/auth.service';
import { RestService } from '@services/rest/rest.service';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private restService: RestService
  ) { }

  intercept(requestToHandle: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = requestToHandle.headers.has('skipAuth');
    let headers: HttpHeaders = new HttpHeaders();
    if (this.restService.isFormPaypalData) {
      // headers.set('Content-Type', 'application/x-www-form-urlencoded').set('Accept', 'application/json');
      this.restService.isFormPaypalData = false;
    } else {
      headers.set('Content-Type', 'application/json').set('Accept', 'application/json');
    }
    // let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    if (skipIntercept) {
      const noAuth = requestToHandle.clone({ headers });
      return next.handle(noAuth);
    }

    return from(this.authService.getToken()).pipe(mergeMap(
      tkn => {
        // Create headers and set token header
        headers = headers.append('Authorization', `Bearer ${tkn}`);
        // Clone our request with the new headers because HttpRequests are immutable
        const authReq = requestToHandle.clone({ headers });

        return next.handle(authReq);
      }
    ));
  }
}
