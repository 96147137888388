import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: Storage
  ) { }

  async getItem(item: string): Promise<any> {
    return await this.storage.get(item);
  }

  async setItem(item: string, value: any): Promise<void> {
    return await this.storage.set(item, value);
  }

  async removeItem(item: string): Promise<void> {
    return await this.storage.remove(item);
  }

  async clear(): Promise<void> {
    return await this.storage.clear();
  }

  async getAccessToken(): Promise<void> {
    return await this.getItem('student_token');
  }

  async setAccessToken(accessToken: any): Promise<void> {
    return await this.storage.set('student_token', accessToken);
  }

}
