// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverPath: 'https://www.backoffice4steps.com/api/v1/',
  recaptcha: {
    // siteKey: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU'
    secretKey: '6LfeGwMiAAAAAHCKP437Aa9a1SI9eZO3dPofKDQd',
    siteKey: '6LfeGwMiAAAAAHrlviFGQLGRMV0u55iiI3RSnHK8'
  },
  cron: 120,
  apiUrlSocket: 'https://websocket.wellington-academy.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
