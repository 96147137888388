import { NgModule } from '@angular/core';

import { DateAgoPipe } from './date-ago/date-ago';
import { SafePipe } from './safe/safe';


@NgModule({
	declarations: [
		DateAgoPipe,
		SafePipe
	],
	imports: [],
	exports: [
		DateAgoPipe,
		SafePipe
	]
})
export class PipesModule {}
