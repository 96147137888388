/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule } from '@ngx-translate/core';

import { ModalAllPaymentComponent } from './modal/payment/modal-all-payment/modal-all-payment.component';
import { SharedModule } from './shared.module';
import { ModalAllPaymentBothComponent } from './modal/payment/modal-all-payment-both/modal-all-payment-both.component';
import { ModalAllPaymentMonthlyComponent } from './modal/payment/modal-all-payment-monthly/modal-all-payment-monthly.component';
import { ModalInfoCheckSubscriptionComponent } from './modal/info-check-subscription/modal-info-check-subscription/modal-info-check-subscription.component';
import { ModalWinnerCoinComponent } from './modal/winner-coin/modal-winner-coin/modal-winner-coin.component';
import { ModalLevelFinishComponent } from './modal/level-finish/modal-level-finish/modal-level-finish.component';
import { IconsShortcutsComponent } from './icons-shortcuts/icons-shortcuts.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { ModalEnrollFreeComponent } from './modal/enroll-free/modal-enroll-free/modal-enroll-free.component';
import { MenuComponent } from './menu/menu.component';
import { ButtonEnrollComponent } from './button/button-enroll/button-enroll.component';
import { HeaderInformationComponent } from './header/header-information/header-information.component';
import { ParallaxImageComponent } from './parallax-image/parallax-image.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderBarComponent } from './header/header-bar/header-bar.component';
import { WelcomeTextComponent } from './welcome-text/welcome-text.component';

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      return params.interpolateParams['default'] || params.key;
    }
    return params.key;
  }
}

@NgModule({
  declarations: [
    ModalAllPaymentComponent,
    ModalAllPaymentBothComponent,
    ModalAllPaymentMonthlyComponent,
    ModalInfoCheckSubscriptionComponent,
    ModalWinnerCoinComponent,
    ModalLevelFinishComponent,
    ModalEnrollFreeComponent,
    IconsShortcutsComponent,
    HeaderMenuComponent,
    MenuComponent,
    ButtonEnrollComponent,
    HeaderInformationComponent,
    ParallaxImageComponent,
    FooterComponent,
    HeaderBarComponent,
    WelcomeTextComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper }
    }),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    ModalAllPaymentComponent,
    ModalAllPaymentBothComponent,
    ModalAllPaymentMonthlyComponent,
    ModalInfoCheckSubscriptionComponent,
    ModalWinnerCoinComponent,
    ModalLevelFinishComponent,
    ModalEnrollFreeComponent,
    IconsShortcutsComponent,
    HeaderMenuComponent,
    MenuComponent,
    ButtonEnrollComponent,
    HeaderInformationComponent,
    ParallaxImageComponent,
    FooterComponent,
    HeaderBarComponent,
    WelcomeTextComponent
  ]
})
export class ComponentsModule { }
