/* eslint-disable radix */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { ProgressUser, ProgressStatistics } from '@interfaces/progress';
import { GlobalVarsService } from '@services/global-vars/global-vars.service';
import { RestService } from '@services/rest/rest.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

    public readonly progressUserDefault = {
        rooms: {
            avg_score: 0,
            completed: 0
        },
        levels: {
            avg_score: 0,
            completed: 0
        }
    };
    public progressUser = {};
    public readonly progressStatisticsDefault: ProgressStatistics = {
      completed: 0,
      avg_score: 0,
    };

    constructor(
        private globalVars: GlobalVarsService,
        private restService: RestService,
        private storage: Storage,
    ) { }

    /*========================================================================*\
    * Update
    /*========================================================================*/

    async updateProgress(): Promise<void> {
        const data = {
            progress: this.progressUser
        };
        const url = 'student/update-progress';

        try {
            const resp = await this.restService.postData(data, url);
            if (resp) {
                const response = resp.body;
                console.log(response);
                console.log('Update Progress: ', response);
            }
        } catch(error) {
            const response = error.error;
            console.error(response);
        }
    }

    /*========================================================================*\
    * Create Schema
    /*========================================================================*/

    async createProgress(
        levelID: string,
        unitID: string
    ): Promise<void> {
        const progressStatisticsDefault: ProgressStatistics = this.progressStatisticsDefault;
        const currentLevel = levelID;
        const currentUnit = unitID;
        const currentProgress: any = await this.getCurrentProgress();

        if (Object.keys(currentProgress).length === 0) {
            this.progressUser = this.progressUserDefault;
        } else {
            this.progressUser = currentProgress.body;
        }

        if (!this.progressUser[currentLevel]) {
            this.progressUser = {
                ...this.progressUser,
                [currentLevel]: {
                    ...progressStatisticsDefault,
                    [currentUnit]: {
                        ...progressStatisticsDefault,
                        ...this.getUnitProgressJson()
                    }
                }
            };
        } else {
            if (!this.progressUser[currentLevel][currentUnit]) {
                this.progressUser = {
                    ...this.progressUser,
                    [currentLevel]: {
                        ...this.progressUser[currentLevel],
                        [currentUnit]: {
                        ...progressStatisticsDefault,
                        ...this.getUnitProgressJson()
                        }
                    }
                };
            }
        }

        if(this.progressUser[currentLevel][currentUnit]) {
            await this.updateProgress();
            console.log('progress schema created :: ', this.progressUser);
        }
    }

    /*========================================================================*\
     * Reset Progress
    /*========================================================================*/

    async resetProgress(
        levelID: string,
        unitID: string,
        key: string
    ): Promise<void> {
        const currentLevel = `level_${ levelID }`;
        const currentUnit = `unit_${ unitID }`;
        const progressDefault = this.getUnitProgressJson();
        const currentProgress: any = await this.getCurrentProgress();
        if (currentProgress) {
            this.progressUser = currentProgress.body;
            this.progressUser[currentLevel][currentUnit][key] = progressDefault[key];
            if(this.progressUser[currentLevel][currentUnit]) {
                await this.updateProgress();
            }
        }
    }

    /*========================================================================*\
    * Save
    /*========================================================================*/

    async saveProgress(
        levelID: string,
        unitID: string,
        data: { [key: string]: any } = {},
        numUnits = 0
    ): Promise<void> {
        const currentLevel = levelID;
        const currentUnit = unitID;
        const currentProgress: any = await this.getCurrentProgress();
        if (currentProgress) {
            this.progressUser = currentProgress.body;
        }

        if (this.progressUser[currentLevel][currentUnit]) {
            const { key, value } = data;
            const checkArr = value instanceof Array;

            if (checkArr) {
                value.map((val) => {
                    if (val.key.includes('.')) {
                        const keys = val.key.split('.');
                        this.progressUser[currentLevel][currentUnit][key][keys[0]][keys[1]] = val.value;
                    } else {
                        this.progressUser[currentLevel][currentUnit][key][val.key] = val.value;
                    }
                });
            } else {
                this.progressUser[currentLevel][currentUnit][key] = value;
            }

            await this.handleProgress(currentLevel, currentUnit, numUnits);
            console.log('progress user :: ', this.progressUser);
        }
    }

    /*========================================================================*\
    * Handle
    /*========================================================================*/

    async handleProgress(
        currentLevel: string,
        currentUnit: string,
        numUnits: number
    ): Promise<void> {
        const unitProgress = this.getUnitProgress(currentLevel, currentUnit);
        if (unitProgress) {
            this.progressUser[currentLevel][currentUnit].completed = unitProgress.completed;
            this.progressUser[currentLevel][currentUnit].avg_score = unitProgress.avg_score;
        }

        const levelProgress = this.getLevelProgress(currentLevel, numUnits);
        if (levelProgress) {
            this.progressUser[currentLevel].completed = levelProgress.completed;
            this.progressUser[currentLevel].avg_score = levelProgress.avg_score;
        }

        this.storage.set('student_progress', JSON.stringify(this.progressUser));

        if (typeof this.globalVars.student_token === undefined || this.globalVars.student_token === '') {
        return;
        }

        await this.updateProgress();
    }

    /*========================================================================*\
    * Level
    /*========================================================================*/

    getLevelProgress(level_id: string, nunits: any): any {
        const progress: ProgressStatistics = this.progressStatisticsDefault;
        progress.completed = 0;
        progress.avg_score = 0;
        
        if (this.progressUser[level_id]) {
            const current_progress = this.progressUser[level_id];
            // const nUnits = parseInt(nunits.replace('unit_', ''));
            let completed_units = 0;
            let units_avg = 0;

            if (typeof current_progress !== 'undefined') {
                for (const key in current_progress) {
                    if (key.startsWith('unit_')) {
                        completed_units += current_progress[key].completed;

                        // const unit_id = parseInt(key.replace('unit_', ''));
                        const unit_progress = this.getUnitProgress(level_id, key);
                        if (unit_progress) {
                            units_avg += unit_progress.avg_score;
                        }
                    }
                }

                progress.completed = completed_units / nunits;
                progress.avg_score = units_avg / nunits;

                if (isNaN(progress.completed)) {
                    progress.completed = 0;
                }

                if (isNaN(progress.avg_score)) {
                    progress.avg_score = 0;
                }
            }
        }

        return progress;
    }

    /*========================================================================*\
    * Unit
    /*========================================================================*/

    getUnitProgress(
        level_id: string,
        unit_id: any
    ): ProgressUser | any {
        // console.log('Level: ', level_id);
        // console.log('Unit: ', unit_id);
        // console.log(this.progressUser);

        const currentLevel = level_id;
        const currentUnit = unit_id;
        const progress: ProgressStatistics = this.progressStatisticsDefault;

        if (this.progressUser[currentLevel] && this.progressUser[currentLevel][currentUnit]) {
            const current_progress = this.progressUser[currentLevel][currentUnit];
            const totalQuestions = 75;
            let completed_questions = 0;
            let correct_questions = 0;

            if (typeof current_progress !== 'undefined') {

                if (current_progress.main_video) {
                    completed_questions++;
                    correct_questions++;
                }

                if (current_progress.video_practice_1) {
                    completed_questions++;
                    correct_questions++;
                }

                if (current_progress.video_practice_2) {
                    completed_questions++;
                    correct_questions++;
                }

                if (current_progress.video_practice_3) {
                    completed_questions++;
                    correct_questions++;
                }

                if (current_progress.vocabulary) {
                    completed_questions++;
                    correct_questions++;
                }

                for (const translating_key in current_progress.translating.questions) {
                    if (current_progress.translating.questions[translating_key] !== 0) {
                        completed_questions++;
                        if (current_progress.translating.questions[translating_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                for (const translating2_key in current_progress.translating2.questions) {
                    if (current_progress.translating2.questions[translating2_key] !== 0) {
                        completed_questions++;
                        if (current_progress.translating2.questions[translating2_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                for (const listening_key in current_progress.listening.questions) {
                    if (current_progress.listening.questions[listening_key] !== 0) {
                        completed_questions++;
                        if (current_progress.listening.questions[listening_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                for (const listening2_key in current_progress.listening2.questions) {
                    if (current_progress.listening2.questions[listening2_key] !== 0) {
                        completed_questions++;
                        if (current_progress.listening2.questions[listening2_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                for (const speaking_key in current_progress.speaking.questions) {
                    if (current_progress.speaking.questions[speaking_key] !== 0) {
                        completed_questions++;
                        if (current_progress.speaking.questions[speaking_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                for (const speak_up_key in current_progress.speak_up.questions) {
                    if (current_progress.speak_up.questions[speak_up_key] !== 0) {
                        completed_questions++;
                        if (current_progress.speak_up.questions[speak_up_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                for (const listen_up_key in current_progress.listen_up.questions) {
                    if (current_progress.listen_up.questions[listen_up_key] !== 0) {
                        completed_questions++;
                        if (current_progress.listen_up.questions[listen_up_key] === 1) {
                            correct_questions++;
                        }
                    }
                }

                if (current_progress.completed !== 100) {
                    // progress.completed += (((100 / 7) * 3) / 60) * completed_questions;
                    progress.completed = (100 * completed_questions) / totalQuestions;
                    progress.completed = Math.round(progress.completed);
                } else {
                    progress.completed = 100;
                }

                if (completed_questions) {
                    progress.avg_score = Math.round((10 / completed_questions) * correct_questions * 10) / 10;
                }

                if (isNaN(progress.completed)) {
                    progress.completed = 0;
                }

                if (isNaN(progress.avg_score)) {
                    progress.avg_score = 0;
                }
            }
        }

        return progress;
    }

    /*========================================================================*\
    * Current
    /*========================================================================*/

    async getCurrentProgress(): Promise<void> {
        try {
            const url = 'student/progress';
            const result: any = await this.restService.postData({}, url);

            if (result) {
                return result;
            }
        } catch (err) {
            console.error('Error: ', err);
        }
    }

    /*========================================================================*\
    * Schema
    /*========================================================================*/

    getUnitProgressJson(): ProgressUser {
        const unit_progress: ProgressUser = {
            avg_score: 0,
            completed: 0,
            main_video: false,
            video_practice_1: false,
            video_practice_2: false,
            video_practice_3: false,
            translating: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                },
            },
            translating2: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                },
            },
            listening: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                },
            },
            listening2: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                },
            },
            speaking: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                },
            },
            speak_up: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                }
            },
            listen_up: {
                current_question: 1,
                questions: {
                question_1: 0,
                question_2: 0,
                question_3: 0,
                question_4: 0,
                question_5: 0,
                question_6: 0,
                question_7: 0,
                question_8: 0,
                question_9: 0,
                question_10: 0
                }
            },
            vocabulary: false
        };

        return unit_progress;
    }

}
